﻿.tours-count {
    position: relative;
    /*bottom: -7px;*/
}

.tour-list {
    max-width: 400px;
    margin: 0 auto;

    ul {
        margin-bottom: .5em;

        :last-child {
            border-bottom: none;
        }
    }

    .fa-arrows {
        cursor: move;
    }

    .tour-item {
        background-color: white;
        padding: 5px;
        border-bottom: solid 1px #bbb;
        display: flex;
        flex-direction: row;
        box-shadow: #bbb 1px 1px 3px 0px;

        input {
            border: none;
        }

        &:focus input {
            border: inherit;
        }

        .preview-tour {
            margin: 0 .4em 0 0;
        }
    }
}

li.tour-item:first-child {
    /*border-radius: 5px 5px 0 0;*/
}

li.tour-item:last-child {
    /*border-radius: 0 0 5px 5px;*/
    /*border-bottom: none;*/
}

.tour-control {
    padding-top: 4px;
    padding-left: 7px;
    padding-right: 7px;

    /*&.fa-arrows {
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }*/    
}

.tour-content {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: auto;
    margin-right: 5px;
}

.tour-delete {
    color: lightgray;
    background-color: #3f3f3f;
}