﻿.listing-info-box {
    /*text-align: center;
    background: $lightGray;
    margin-top: -10px;*/
    clear: both;

    .level {
        /*position: fixed;*/
        z-index: 25;
    }

    .package-label {
        padding: .4em .8em;
        background-color: $lightGray;
        font-weight: bold;
        /*white-space: nowrap;
        overflow: hidden;*/
        text-overflow: ellipsis;
        font-size: 1.25em;

        .link-listing {
            opacity: 0;

            &:hover {
                opacity: 1;
            }
        }

        .icon-text {
            vertical-align: baseline;
        }

        .icon {
            /*display: initial;*/
            margin-top: 0.2em;
        }

        .dropdown-menu {
            margin-left: -180px;
            white-space: nowrap;

            a {
                color: inherit;
            }
        }

        .dropdown-trigger {
            background: #fff;
            border-radius: 5px;
            padding: 0 .2em;
        }
    }

    .level.is-mobile .level-item {
        margin-right: 0rem;
    }

    .extended-info {
        padding-top: 1em;
    }

    .listing-select-notify {
        max-width: fit-content;
        margin: auto;
        padding: 0;
        white-space: normal;
        line-height: 1.4em;
        font-size: initial;
        font-weight: initial;

        .buttons {
            margin-left: .5em;
        }

        .is-clickable {
            font-weight: bold;
            font-style: normal;
        }

        .suggested {
            font-style: normal;

            .selected-listing {
                font-weight: bold;
            }
        }
    }
}

.listing-info-separator {
    color: $lakeBlue;
    padding-left: 15px;
    padding-right: 15px;
}

.search-form-dd {
    min-width: 105px;
}

.listingSearchResultList {
    max-height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: -17px;
}

.listingSelectButton {
    right: 10px;
}

.searchResultView {
    margin-bottom: 40px;
}

.listing-select-button-container {
    position: relative;
}

.listing-select-button {
    position: absolute;
    top: 18px;
}

.link-listing-content {
    width: 500px;
    margin-right: auto;
    margin-left: auto;
}

.listing-link-message {
    /*color: red;*/
    font-size: 1rem;
    font-weight: normal;
    font-style: italic;
    line-height: 2.4em;
    max-width: fit-content;
    margin: auto;
    padding: 0.4em 2em;

    .is-clickable {
        font-weight: bold;
        font-style: normal;
    }

    .suggested {
        font-weight: bold;
        font-style: normal;
    }
}

.listing-link-search-results {
    margin-left: 0 !important;
}

.listing-link-item {
    list-style: none !important;
    margin-bottom: 10px;
    display: flex;
}

.listing-link-item-column {
    padding: 5px;
    display: inline;
}

.listing-link-button {
    margin-top: 7px;
    margin-right: 10px;
}

.link-listing-instructions {
    font-size: 20px;
    font-style: italic;
}
