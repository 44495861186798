﻿.password-reset-link {
    color: blue;
    text-decoration: underline;
    font-size: 11px;
    cursor: pointer;
    margin-top: 1em;
}

.password-reset-input {
    width: 598px;
}

.connect-logo {
    margin: 0 auto;
    width: fit-content;
    padding-top: 0.8em;
}

.login-box {
    .card {
        max-width: 320px;

        .card-header-title {
            padding: .75rem 1.5rem;
        }

        .button {
            margin: auto;
        }

        .card-content {
            padding: 0 2rem 2rem 2rem;
        }
    }

    .divider {
        display: block;
    }

    .vendor-help {
        float: right;
        margin-bottom: .8em;
    }
}