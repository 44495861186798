@charset "utf-8";

// Import Bulma's default variables
@import "../node_modules/bulma/sass/utilities/initial-variables.sass";


$family-sans-serif: "Roboto", BlinkMacSystemFont, -apple-system, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

// Colors: Primary
$aspenGold: #f29d2e;
$aspenOrange: #B9461D;
$coloradoGreen: #4E5736;

$lightGreen: #9AA56A;
$darkBlue: #003B5B;
$lightBlue: #076A9A;
$darkGrey: #3E3E3E;
$lightGrey: #D2D2D2;

$lakeBlue: $darkBlue;
$lightLakeBlue: #eaf8ff;

// Colors: Secondary
$gray: #3E3E3E;
$lightGray: #EEEEEE; // not a typo: kept for backwards compability until $lightGrey is honored
$recoGray: #d2d2d2;
$lightGreen: #BCC981; //#2ad767;
$overlaySuccess: #0bd953;
$light-danger: #eea891;

// Bulma Color assignments
$primary: $aspenGold;
$primary-invert: white;
$info: $lakeBlue;
$info-invert: white;
$success: $lightGreen;
$success-invert: white;
$warning: $aspenOrange;
$warning-invert: white;
$danger: #dd5326; // #d13030;
$danger-invert: white;
$dark: $gray;
$dark-invert: white;
$progress: #3273dc;

$link: $primary; // TODO: typically brand colors are not also used for default navigation

$card-header-background-color: $lightGray;
$card-content-padding: .75rem;

// Import the rest of Bulma
@import "../node_modules/bulma/bulma.sass";

// Import app styles
@import "../App/ptuApp.scss";
@import "../App/NavBar/navBar.scss";
@import "../App/Email/emailmodal.scss";
@import "../App/Listings/listings.scss";
@import "../App/Packages/packages.scss";
@import "../App/Packages/History/packageHistory.scss";
@import "../App/Photos/PhotoManager.scss";
@import "../App/Tours/tours.scss";
@import "../App/Login/login.scss";
@import "../App/Login/PasswordReset/passwordReset.scss";
@import "../App/Listings/ListingSelect/ListingSelect.scss";
@import "../App/Utilities/EmailSubscription/emailSubscription.scss";
@import "../App/Utilities/growl/growl.scss";
@import "../App/Utilities/AccountSettings/accountSettings.scss";
@import "../App/Utilities/PublishPermissions/PublishPermissions.scss";
@import "../Scripts/non-managed-extensions/toggle-switch.scss";

//remove .css extension when importing normal css files
@import "../Scripts/non-managed-extensions/angular-animated-ellipsis";
@import "../Scripts/non-managed-extensions/angular-sortable-view";
@import "../Scripts/non-managed-extensions/ng-sortable";

// Color overrides
/*strong.is-danger {
  color: $danger;
}

span.is-danger {
  color: $danger;
}

span.is-primary {
  color: $primary !important;
}

span.is-info {
    color: $info;
}*/

/*.fa.is-primary {
    color: $primary;
}

.fa.is-info {
    color: $info;
}

.fa.is-success {
    color: $success;
}

.fa.is-warning {
    color: $warning;
}

.fa.is-danger {
    color: $danger;
}*/

.box.card {
    padding: 0;
}

.navbar-item a:hover,
a.navbar-item:hover,
.navbar-item a.is-active,
a.navbar-item.is-active {
    color: $coloradoGreen !important;
    /*border-bottom-color: $danger !important;*/
}

.navbar-item a.is-tab.is-active,
a.navbar-item.is-tab.is-active {
    color: $primary !important;
    border-bottom-color: $primary !important;
}

.content {
    /*margin: 10px;*/
}

.content pre {
    overflow: unset !important;
}

//Template Panels
.panel-heading.is-primary {
    background-color: $primary;
    color: $primary-invert;
}

.panel-heading.is-info {
    background-color: $info;
    color: $info-invert;
}

// Bulma Fixes
.first-tab-fix {
    margin-top: 0.25em;
}

.tab-margin-override {
    margin: 0 !important;
}

.space-between {
    justify-content: space-between !important;
}

.panel-tabs {
    margin-bottom: 0 !important;
    overflow-x: auto;
}

.fixed-navbar-fix {
    margin-top: 52px;
}

.tabs li.is-active a {
    border-bottom-color: $primary;
    color: $primary;
}

.select:not(.is-multiple)::after {
    border-color: $primary;
}

input[type=text]:focus,
input[type=password]:focus,
textarea:focus {
    border: 1px solid $primary;
    outline-color: $primary;
    box-shadow: 0 0 5px $primary;
}

.select select:focus,
.select select.is-focused,
.select select:active,
.select select.is-active {
    border: 1px solid $primary !important;
    outline-color: $primary !important;
    box-shadow: 0 0 5px $primary !important;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-text {
    text-overflow: ellipsis;
    overflow: hidden;
    flex: auto;
    white-space: nowrap;
}

// Bulma Extensions
.is-borderless {
    border: none !important;
}

.is-block-important {
    display: block !important;
}

.is-relative-important {
    position: relative !important;
}

.is-padded {
    padding: 1.5em;
}

// Modals
.modal-textarea {
    width: 100%;
}

//Override default values for IE
.modal-content,
.modal-card {
    margin: 0px;
}

.modal {
    z-index: 40 !important;
}

// Tool Tips
.tooltip {
    position: relative;
    display: inline-block;

    &:hover .tooltiptext {
        visibility: visible;
    }

    .tooltiptext {
        visibility: hidden;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        /* Position the tooltip */
        position: absolute;
        top: -25px;
        z-index: 1;
        white-space: nowrap;
        opacity: .9;
    }
}

.is-underlined {
    text-decoration: underline;
    cursor: pointer;
}

//Progress
.progress::-webkit-progress-value {
    background-color: $progress !important;
}

.progress::-moz-progress-bar {
    background-color: $progress !important;
}

.progress::-ms-fill {
    background-color: $progress !important;
    border: none;
}

.button.is-outlined {
    background-color: white !important;
}