﻿$image-width: 148px;
$image-height: 148px;
$image-radius: 4px;
$image-width-small: 120px;
$image-height-small: 120px;


body {
    overflow: auto;
    /*background-color: #eee;*/
}

.main-body {
    margin-top: 20px; /**/
}

.section.main-section {
    /*padding-top: 10px;*/
}

.content {
    blockquote {
        max-width: 85%;
        margin: auto;
        padding: 0.5em 0.75em;

        &.is-large {
            padding: 1.25em 1.5em;
        }
    }

    blockquote:before {
        /*Hiding qoute for now*/
        display: none;
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        font-family: 'Times New Roman';
        line-height: 0.1em;
        margin-right: 0.1em;
        vertical-align: -0.4em;
    }
}

.error-block {
    background: #ffffc3;
}

.button {
    border-radius: 2em;

    &.is-primary {
        min-width: 5em;
    }

    &.is-small {
        border-radius: 1em;
        padding: 0 0.8em 0em 0.8em;
        height: 2em;
    }

    &.button-left {
        border-radius: 2em 0 0 2em;
        margin-right: 0 !important;
    }

    &.button-middle {
        border-radius: 0;
    }

    &.button-right {
        border-radius: 0 2em 2em 0;
        margin-left: 0 !important;
    }
}

.button.is-primary.is-outlined:hover,
.button.is-primary.is-outlined:focus {
    background-color: $primary !important;
    border-color: $primary !important;
    color: white !important;
}

.button.is-info.is-outlined:hover,
.button.is-info.is-outlined:focus {
    background-color: $info !important;
    border-color: $info !important;
    color: white !important;
}

.button.is-success.is-outlined:hover,
.button.is-success.is-outlined:focus {
    background-color: $success !important;
    border-color: $success !important;
    color: white !important;
}

.button.is-warning.is-outlined:hover,
.button.is-warning.is-outlined:focus {
    background-color: $warning !important;
    border-color: $warning !important;
    color: white !important;
}

.button.is-danger.is-outlined:hover,
.button.is-danger.is-outlined:focus {
    background-color: $danger !important;
    border-color: $danger !important;
    color: white !important;
}

.card-header {
    background-color: #eeeeee;
}

hr.sm {
    margin: 1em 5em;
}

.is-clickable, .dropdown-trigger {
    cursor: pointer;
}

.navbar-item, .dropdown-item {
    font-size: 1rem;
}

.tall-content {
    overflow: auto;
    max-height: 200px;
    background-color: #fff;
    padding: 1em;
    border: 1px solid #ccc;
}

.global-modal, .modal {
    font-style: normal;

    .message-header {
        padding: 0.6em 1.25em;
        background-color: $recoGray;
        color: #222;
        font-weight: bold;
    }

    .button-footer {
        margin-top: 1em;
        min-height: 2em;
    }
}

.full-width, input.full-width, input.input.full-width {
    width: 100%;
    max-width: 100%
}

.level-right:last-child, .level.is-mobile .level-item {
    margin-right: 0;
}

.sticky-disabled {
    position: inherit;
    bottom: inherit;
    top: inherit;
}

.breadcrumb li + li::before {
    margin-right: 0.8em;
    content: ">";
}

.breadcrumb:not(:last-child) {
    margin-bottom: 1rem;
}

.nowrap {
    white-space: nowrap;
}

.cursor-move {
    cursor: move;
}

.modal {
    &.error-modal {
        z-index: 50 !important;
    }
}

.dhr {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    margin: 0.5em 0.5em 1em 0.5em;
    clear: both;

    > div {
        margin: 0 1rem;
        display: inline;
        font-weight: bold;
    }

    &:before, &:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '\a0';
        background-color: #777;
    }

    &:before {
        margin-left: -50%;
        text-align: right;
    }

    &.sm {
    }
}

.dhr-top-margin-override {
    margin-top: 0.5em !important;
}

input.input, select.input, textarea.input {
    max-width: 280px;
}

.vendor-nav-button {
    width: 180px;
    height: 180px;
    text-align: center;
    margin: 10px;
}

footer {
    .level {
        max-width: 200px;
    }
}

.add-right-button-margin {
    margin-right: 10px;
}

.add-left-button-margin {
    margin-left: 10px;
}

.collapsable-row {
    cursor: pointer;
    margin-bottom: 0px !important;
}

.pulsate {
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.5;
}

.pulsate-fast {
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
}

@keyframes pulsate {
    0% {
        opacity: 0.25;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        opacity: 0.25;
    }
}

.show-password {
    position: relative;
    left: -42px;
    font-size: 1.5em !important;
    padding: 5px;
}

/*Hide password review eye in EDGE*/
::-ms-reveal {
    display: none;
}

.form-instructions {
    font-size: 20px;
    font-style: italic;
}

.search-spinner {
    font-size: 1.8em !important;
    position: relative;
    top: -.2em;
    left: .3em;
}

.content-auto-size {
    max-width: 750px;
    margin: 0 auto;
}

.detail-page-content {
    margin-right: auto;
    margin-left: auto;
    margin-top: 4em;
    margin-bottom: 1em;
    max-width: 50em;
}

@media only screen and (max-device-width: 480px) {
    .detail-page-content {
        margin-top: 2em;
        max-width: 30em;
    }
}

.search-results {
    .card {
        margin-bottom: 15px;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 0.5rem 0.3rem #ccc;
        }
    }

    .card-header {
        padding: .75rem;
        background: #eee;
    }

    .card-content {
        padding: .75rem;
    }
}

.social-media-bar {
    margin: auto;
    padding-bottom: 1em;
}

.table.is-centered {
    margin-right: auto;
    margin-left: auto;
}

.inverted-navbar-link {
    background-color: $primary !important;
    border-color: $primary !important;
    color: white !important;
}

.inverted-navbar-link:not(.is-arrowless)::after {
    border-color: white !important;
}


.inverted-navbar-link:hover {
    color: $primary !important;
}

.inverted-navbar-link:not(.is-arrowless):hover::after {
    border-color: $primary !important;
}