﻿@use 'sass:math';

.sortable-image-container {
    float: left;
    width: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 20px;
    margin-bottom: 20px;
    border: 1px solid darkgray;
    border-radius: $image-radius;
    background-color: white;
}

.photo-browser-container {
    margin-top: 15px;
    margin-bottom: 5px;
 }

.photo-browser-column {
    padding-bottom: 0px;
    position: relative;
}

.photo-browser-button {
    margin-bottom: 10px;
}

.photo-browser-count {
    position: relative;
    bottom: -5px;
}

.photo-browser-count-message {
    position: relative;
    bottom: -5px;
}

.image-loading {
    width: $image-width;
    height: $image-height;
    background-color: gray;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent));
    background-image: -moz-linear-gradient(rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    background-image: -o-linear-gradient(rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    -webkit-background-size: 50px 50px;
    -moz-background-size: 50px 50px;
    background-size: 8px 8px;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    padding-top: math.div($image-height, 2) - 10;
    position: absolute;
    top: 0px;
}

.image-loading-header {
    color: white;
    position: relative;
    top: 15px;
}

.image-matrix-download-header {
    color: $overlaySuccess;
    position: relative;
    top: 15px;
}

.image-container {
    margin: 0px;
    padding: 0px;
    width: $image-width;
    height: 88px;
    position: relative;
}

.image-state {
    color: $primary;
    position: absolute;
    top: 0px;
    left: 0px;
}

.spinning {
    animation-name: spin;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* transform: rotate(3deg); */
    /* transform: rotate(0.3rad);/ */
    /* transform: rotate(3grad); */
    /* transform: rotate(.03turn);  */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.sortable-image-container {
    float: left;
    width: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 20px;
    margin-bottom: 20px;
    border: 1px solid darkgray;
    border-radius: $image-radius;
    background-color: white;
}

.image-dropzone {
    width: $image-width;
    height: $image-height;
    padding-top: 45px;
    padding-left: 42px;
    background-color: #f5f5f5;
}

.image-preview {
    width: $image-width;
    height: $image-height;
    background-color: #f5f5f5;
    position: relative !important;
    background-color: gray;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent));
    background-image: -moz-linear-gradient(rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    background-image: -o-linear-gradient(rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    -webkit-background-size: 50px 50px;
    -moz-background-size: 50px 50px;
    background-size: 8px 8px;
}

.rotateable-image {
    width: $image-width-small;
    height: $image-height-small;
    background-color: #f5f5f5;
    position: relative !important;
    background-color: gray;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent));
    background-image: -moz-linear-gradient(rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    background-image: -o-linear-gradient(rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, .2) 50%, transparent 50%, transparent);
    -webkit-background-size: 50px 50px;
    -moz-background-size: 50px 50px;
    background-size: 8px 8px;
}

.dropzone-image {
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles */
        max-height: $image-height;
        max-width: $image-width;
    }
}

.image-uploaded-overlay {
    width: $image-width;
    height: $image-height;
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    padding: 50px 53px;
}

.image-matrix-overlay {
    background-color: black;
    opacity: 0.5;
    padding-left: 6px;
    padding-right: 6px;
    position: absolute;
    top: 125px;
    left: 0px;
    width: 148px;
}

.image-matrix-header {
    color: $overlaySuccess;
    padding-left: 6px;
    padding-right: 6px;
    position: absolute;
    top: 125px;
    left: 0px;
    width: 148px;
    text-align: center;
}

.image-toosmall-overlay {
    background-color: black;
    opacity: 0.5;
    padding-left: 6px;
    padding-right: 6px;
    position: absolute;
    top: 101px;
    left: 0px;
    width: 148px;
    height: 48px;
}

.image-toosmall-header {
    color: $primary;
    padding-left: 6px;
    padding-right: 6px;
    position: absolute;
    top: 101px;
    left: 0px;
    width: 148px;
    text-align: center;
}

.image-delete-overlay {
    color: red;
    position: absolute;
    top: 50px;
    left: 53px;
    z-index: 20;
}

img.image-preview {
    border-top-left-radius: $image-radius;
    border-top-right-radius: $image-radius;
}

.sortable-image-tool-bar {
    border-top: 1px solid #e3e3e3
}

.sortable-image-tool {
    margin: 6px;
    cursor: pointer;
}

.photo-drop-zone {
    /*border: 2px dashed gray;
    border-radius: 8px;*/
    box-shadow: inset 0 0 8px 0 #bbb;
    /*padding: 4px;*/
    overflow: auto;

    .drag-helper {
        clear: both;
        padding: 1rem .5rem;
    }
}

.sort-button {
    margin-top: 4px;
    margin-left: 3px;
    cursor: pointer;
}

.warning-section {
    background-color: #fffbeb;
    padding: 0.2em .8em;
    border: 2px dashed #ddd;
    margin-right: 0.5rem;
    display: flex;
    flex-wrap: wrap;

    .warning-section-label {
        font-size: .9em;
    }

    .warning-message {
        padding: 1em 1em 0 0;
        font-style: italic;
        /*font-weight: bold;*/
    }

    .warning-section-group {
        margin: auto;
        padding: .25em 0;
        display: flex;
        justify-content: center;
    }

    .buttons:first-child {
        margin-left: 0.5rem;
    }

    .button.is-primary.is-progress {
        color: $lightGray;
    }
}


// mobile
//
@media screen and (max-width: 768px), print {
    .zoom-small {
        .photo-grid {
            min-height: min-content;
            .photobox {
                width: 33.33333%;

                .control-caption {
                    /*max-width: 70%;*/
                }

                > div .do-not-publish, .image-processing {
                    transform: scale(3);
                }
            }
        }

        .quick-edit {
            max-width: 36.66666%;
        }
    }

    .zoom-medium {
        .photo-grid {
            min-height: min-content;
            .photobox {
                width: 50%;

                > div .do-not-publish, .image-processing {
                    transform: scale(5);
                }
            }
        }

        .quick-edit {
            max-width: 55%;
        }
    }

    .zoom-large {
        .photo-grid {
            min-height: min-content;
            .photobox {
                width: 100%;

                > div .do-not-publish, .image-processing {
                    transform: scale(10);
                }
            }
        }

        .quick-edit {
            max-width: 110%;
        }
    }
}

@media screen and (min-width: 769px), print {
    .zoom-small {
        .photo-grid {
            .photobox {
                width: 12.5%;

                > div .do-not-publish, .image-processing {
                    transform: scale(4);
                }
            }
        }

        .quick-edit {
            max-width: 13.75%;
        }
    }

    .zoom-medium {
        .photo-grid {
            .photobox {
                width: 20%;

                > div .do-not-publish, .image-processing {
                    transform: scale(6);
                }
            }
        }

        .quick-edit {
            max-width: 22%;
        }
    }

    .zoom-large {
        .photo-grid {
            .photobox {
                width: 33.33333%;

                > div .do-not-publish, .image-processing {
                    transform: scale(10);
                }
            }
        }

        .quick-edit {
            max-width: 36.66666%;
        }
    }
}

@media (hover: hover) {
    .delete-me {
        display: none;
    }
}

.photo-grid {
    align-items: stretch;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: wrap;
    min-height: 7rem;

    &.is-ancestor {
        margin: 0;
    }

    .photobox {
        padding: 0.1em;
        float: left;
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        width: 33.3333333333%;
        /*max-width: 320px;
        border: solid 1px #ccc;*/
        box-shadow: #bbb 1px 1px 3px 0px;
        overflow: hidden;

        .photo-control-overlay {
            z-index: 1;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }

        .not-published {
            /*opacity: 0.8;*/

            .do-not-publish {
                display: block;
            }
        }

        .photo-control {
            cursor: pointer;
            display: block;
            background-color: #fff;
            padding: 0 3px;
            opacity: .85;
            z-index: 2;
            margin: 2px;
            border-radius: 3px;
            /*font-size: 1.1em;*/
            transition: all 1s ease;

            &.error {
                position: absolute;
                bottom: 2em;
                display: block;
                font-size: .9rem;
                background-color: #fff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 80%;

                &:hover {
                    white-space: inherit;
                }
            }
        }

        &:hover, &:active {
            .delete-me {
                display: block;
            }
        }

        > div {
            background-size: contain;
            background-repeat: no-repeat;

            > img {
                z-index: 0;
            }

            .do-not-publish, .image-processing {
                color: #fff;
                z-index: 1;
                position: absolute;
                top: 40%;
                opacity: .8;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                transform: scale(5);
                text-shadow: 0 0 5px black;

                &.no-scale {
                    transform: none;
                }

                > i {
                    /*transform: scale(5);*/
                }

                .arrange-mode-icon {
                    opacity: .4;
                }
            }

            .do-not-publish {
                display: none;
            }

            .is-published {
                position: absolute;
                position: absolute;
                width: min-content;
                top: 0;
                padding: 0 0.5em 0 0.5em;
                z-index: 1;
                margin: 3px;
                border-radius: 3px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                white-space: nowrap;
                color: #fff;
                text-shadow: 0 0 5px black;
                opacity: .7;

                > i {
                    color: $success;
                }
            }

            .control-drag {
                cursor: move;
                position: absolute;
                top: 0;
            }

            .control-delete {
                position: absolute;
                top: 0;
                right: 50%;
            }

            .control-exclude {
                position: absolute;
                top: 0;
                right: 0;
            }

            .control-caption {
                position: absolute;
                bottom: 0;
                max-width: 98%;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                background-color: #fff;

                textarea {
                    cursor: pointer;
                    line-height: initial;
                    padding: 0;
                    text-align: center;
                }

                .edit-mode & {
                    width: 100%;
                }
            }

            .control-rotate {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    .additional-photos {
        display: none;
    }

    .additional-photos-break {
        margin-bottom: 5em;

        .additional-photos {
            display: block;
            position: absolute;
            width: 80%;
            left: 10%;
        }
    }
}

.quick-edit {
    position: absolute;
    z-index: 35;
    background: #fff;
    padding: 6px;
    width: 100%;
    box-shadow: 0px 0px 15px 0px #888;

    .photo-control {
        cursor: pointer;
        top: 30%;
        position: absolute;
        z-index: 30;
        background: #fff;
        padding: 0.1em;
        border-radius: 3px;
        opacity: .8;

        &.control-delete {
            right: .5em;
        }

        &.control-exit {
            top: -0.8em;
            padding: 0.1em 0.5em;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: .9em;
            white-space: nowrap;
        }
    }

    textarea {
        line-height: initial;
        padding: 0;
    }
}

.edit-photo-thumb {
    > img {
        /*max-height: 200px;*/
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.photo-edit-modal {
    .modal-content {
        /*max-height: calc(100vh - 0px);*/
    }
}

.photos-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    background-color: rgba(255, 255, 255, .6);
}

.toolbar-section {
    margin-bottom: 0.25rem;
}

.photo-manager-button-container {
    flex-wrap: wrap;

    .button {
        margin-top: .5em;
    }

    .photo-dd-text {
        margin-top: .5em;
    }
}