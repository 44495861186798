﻿.account-settings-content {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1.0em;
    max-width: 30em;
}

.account-settings-header {
    margin-top: 1em;
    margin-bottom: 1.0em;
    text-align: center;
    font-size: 20px;
}

.account-settings-read-only {
    background-color: lightgray;
}

.account-settings-address-ta {
    min-height: 4.5em !important;
}

.api-help {
    margin: 0 2em 2em 0;
    background-color: #efefff;
    padding: 0.5em 1em;
    display: inline-flex;
}

.application_key_input {
    max-width: 310px !important;
    margin-bottom: .5em !important;
}
