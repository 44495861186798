﻿.email-subscription-content {
    width: 30em;
    margin-right: auto;
    margin-left: auto;
}

.email-subscription-header {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center
}

.email-subscription-subheader {
    font-weight: bold;
    margin-bottom: 0.5em;
}

.email-subscription-content {
    margin-bottom: 1.5em;
}