﻿.create-buttons {
    width: fit-content;
}

.link-listing-button {
    color: red;
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .level-left + .level-right {
        margin-top: 0;
    }
}

.dhr {
    margin-top: 2em;
}

.provider-signup {
    max-width: fit-content;
    margin: 0 auto;
    text-align: center;
    margin-top: 3em;
    background-color: $lightLakeBlue;
    color: $lakeBlue;

    p {
        background-color: #fff;
        max-width: fit-content;
        margin: 0.5em auto;
        padding: 0.5em 1.5em;

        .button {
            margin: .3em;
        }
    }
}

.package-bottom-menu {
    /*width: 100%;*/
    margin: 0.8em 1.6em;
    /*background: #fff;*/
    /*border-top: 1px solid #ddd;*/
    .is-publish {
        /*position: fixed;
        bottom: 0;
        right: 2em;*/
        z-index: 29;
        margin-right: 0;
    }

    .sticky-disabled {
        position: inherit;
        bottom: inherit;
        top: inherit;
        right: inherit;
        left: inherit;
    }
}

.package-list {
    max-width: 750px;
    margin: 0 auto;

    .tag:not(.tag-mls) {
        font-weight: bold;
    }

    .card-header {
        background: #eee;
    }

    .card {
        margin-bottom: 15px;
        cursor: pointer;
        border: solid 1px #ddd;

        &:hover {
            box-shadow: 0 0 0.5rem 0.3rem #ccc;

            .card-header {
                /*background-color: #ddd;*/
            }
        }

        .card-content {
            .tags {
                margin-bottom: 0;
            }
        }

        .listed-by {
            margin-bottom: .3rem;
        }

        &.card-disabled {
            box-shadow: none;
            opacity: .85;
            cursor: not-allowed;
            font-style: italic;

            .package-status {
                background-color: #aaa;               
            }
        }
    }

    .card-content {
        padding: .75rem;
    }
}

.package-status {
    background-color: $lightGrey;
    color: #fff;
    right: 0;
    transition: all 1s;
    display: flex;
    align-items: center;
    margin: auto;
    padding: 0.2em 0.5em 0.2em 0.8em;
    /*min-width: 150px;*/
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 5px 50%);

    &.is-danger {
        background-color: $danger;
    }

    &.is-warning {
        background-color: $warning;
    }

    &.is-success {
        background-color: $success;
    }

    &.needs-publish {
        background-color: $darkBlue;
    }

    &.needs-action {
        /*background-color: $primary;*/
        box-shadow: 0 0 5px 5px #ffffff;
        animation: needs-action-pulse 2s infinite;
    }

    &.highlight {
        /*box-shadow: -3px 3px 3px 0px red;*/
    }
}

.package-delta {
    padding: 0.3em 0.8em .8em .8em;
}

@keyframes needs-action-pulse {
    0% {
        box-shadow: none;
    }

    50% {
        box-shadow: 0 0 5px 5px #ffffff;
    }

    100% {
        box-shadow: none;
    }
}


@media screen and (max-width: 768px) {
    .package-status {
        /*min-width: 90px;*/
    }
}

.tag-mls {
    margin-left: .3em;
    /*font-family: 'Courier New';*/
    border-radius: 3px;
    /*display: inline !important;*/

    &.tag {
        font-size: 1em;
    }
}

.tag-status {
    &.tag {
        border-radius: 2em;
        position: absolute;
        right: 10px;
        font-size: .8em;
        opacity: .8;
        padding: 0 1em;
    }
}

.loading-package-message {
    margin-top: 4em;
    margin-bottom: 1.5em;
    text-align: center;
}

.package-create-agent-instructions {
    margin-bottom: 0.5em;
}

.package-create-address-input {
    margin-right: .75rem;
    margin-bottom: .75rem;
}
.package-create-address-button {
    margin-bottom: .75rem;
}

.package-sort-button {
    left: -.4em;
}

.publish-failure-box {
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    background: $info;
    font-weight: bold;
    font-size: 13pt;

    a {
        text-decoration: underline;
    }
}