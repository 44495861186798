﻿.reset-box {
    max-width: 550px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}

.validation-rule-pass {
    color: green;
}

.validation-rule-fail {
    color: $danger;
}