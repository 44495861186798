﻿nav {
    .navbar-item {
        cursor: pointer;

        > div.logo {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-height: 50px;
                display: inline-flex;
                margin: 0;
                padding: 0;
            }
        }

        a {
            color: $primary !important;
        }
    }

    .navbar-dropdown.is-active {
        /* TODO: why is native not working? */
        /*opacity: 1;*/
        display: block;
    }
}

@media screen and (min-width: 1024px) {
    .navbar-item.has-dropdown {
        margin-right: 20px;
    }
}

.nav-bar-icon {
    margin-left: 2px;
    margin-right: 3px;
}

.nav-bar-pipe {
    position: relative;
    top: -2px;
}

