﻿.listing-select {
    .readonly {
        padding-top: .5em;
    }

    select.listing-list {
        min-width: 280px;
        max-width: fit-content;
    }

    .refresh-listings {
        font-size: 1.5em;
    }

    .field {
        width: 100%;
    }

    .no-listing-found {
        width: 100%;
        max-width: 350px;
    }

    .listing-flex {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .listing-flex-item {
        display: flex;
        padding: .4em 1em;
    }
}
